function getCookie(name) {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}

async function trigger_sentry_error(error_message, error_stack) {
  const csrftoken = getCookie("csrftoken");
  await fetch(`${window.location.origin}/capture-error/`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
    body: JSON.stringify({
      error: error_message,
      error_stack: error_stack,
    }),
  });
}

async function validateTidByAPI(tid) {
  try {
    const url = `${window._Torus.BFF_BASE_URL}/public/referral/${tid}/verify`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    if (!response.ok) {
      throw new Error("Failed to verify referral code");
    }
    const responseJson = await response.json();
    return responseJson.exists;
  } catch (error) {
    trigger_sentry_error(
      "There was an error while verifying referral code",
      error.stack
    );
    return false;
  }
}

const validateTid = async (context) => {
  let tid = context.tidInputEl.value.trim();
  const tidRegExp = /^T\d{7}$/;
  tid = "T" + tid;
  let isValid = false;
  if (tidRegExp.test(tid) && (await validateTidByAPI(tid))) {
    context.tidErrorEl.style.display = "none";
    context.tidApplyButtonEl.style.display = "none";
    context.tidInputEl.style.borderColor = "#75ffff";
    isValid = true;
    context.greenTickEl.style.display = "block";
  } else {
    context.tidInputEl.style.borderColor = "#E50914";
    context.tidErrorMsgEl.textContent = "Invalid code";
    context.tidErrorEl.style.display = "block";
    context.greenTickEl.style.display = "none";
  }
  return isValid;
};

const handleApplyBtnDisplay = (context, event) => {
  context.tidApplyButtonEl.textContent = "Apply";
  context.tidApplyButtonEl.style.display = "block";
  context.tidInputEl.style.borderColor = "#75ffff";
  context.tidErrorMsgEl.textContent = "";
  context.tidErrorEl.style.display = "none";
  context.greenTickEl.style.display = "none";
};

const isPhoneNumber = (context, phoneNumber) => {
  const phoneRegExp = /^[6-9]\d{9}$/;
  const allSameDigits = context.phoneInputEl.value
    .split("")
    .every((digit) => digit === phoneNumber[0]);
  return (
    phoneNumber.length === 10 && phoneRegExp.test(phoneNumber) && !allSameDigits
  );
};

const validatePhoneNumber = (context) => {
  const phoneNumber = context.phoneInputEl.value.trim();
  if (isPhoneNumber(context, phoneNumber)) {
    context.phoneInputEl.style.borderColor = "";
    return true;
  }
  if (phoneNumber.length >= 10) {
    context.errorBoxEl.textContent = "Please Enter Valid Mobile Number";
    context.errorBoxEl.style.display = "block";
  }
  context.phoneInputEl.style.borderColor = "#E50914";
  return false;
};

const onGetStartedClicked = async (context, event) => {
  event.preventDefault();
  if (context.checkBoxEl.checked) {
    if (validatePhoneNumber(context) && (await validateTid(context))) {
      context.form.submit();
    }
  } else {
    if (validatePhoneNumber(context)) {
      context.form.submit();
    }
  }
};

const onPhoneInputHandler = (context) => {
  if (validatePhoneNumber(context)) {
    context.errorBoxEl.style.display = "none";
  } else {
    context.errorBoxEl.style.display = "block";
  }
  if (context.phoneInputEl.value.length == 0) {
    context.phoneInputEl.style.borderColor = "";
    context.errorBoxEl.style.display = "none";
  }
};

const onPhoneInputKeyPress = (context, event) => {
  const char = String.fromCharCode(event.charCode);
  if (event.charCode === 13) {
    event.preventDefault();
    onGetStartedClicked(context, event);
  }
  if (!/\d/.test(char) || context.phoneInputEl.value.length === 10) {
    event.preventDefault();
  }
};

const onTIDInputKeyPress = (context, event) => {
  const char = String.fromCharCode(event.charCode);
  const clipboardData = event.clipboardData || window.clipboardData;

  if (!/\d/.test(char) || context.tidInputEl.value.length === 7) {
    event.preventDefault();
  }
  if (event.charCode === 13) {
    event.preventDefault();
    handleApplyBtnClick(context, event);
  }
  if (context.tidInputEl.value.length === 6) {
    handleApplyBtnDisplay(context, event);
  }
};

const onTIDPaste = (context, event) => {
  const clipboardData = event.clipboardData || window.clipboardData;
  const pastedData = clipboardData.getData("Text");
  if (!/\d/.test(pastedData) || pastedData.length > 7) {
    event.preventDefault();
    handleApplyBtnClick(context, event);
  }
  if (pastedData.length === 7) {
    handleApplyBtnDisplay(context, event);
  }
};

const onTIDOnchange = (context, event) => {
  if (event.key === "Backspace" || event.key === "Delete") {
    context.tidApplyButtonEl.style.display = "none";
  }
  context.tidInputEl.style.borderColor = "#75ffff";
};

const onTIDInput = async (context, event) => {
  context.tidErrorEl.style.display = "none";
  context.tidInputEl.style.borderBottomColor = "";
};

const handleApplyBtnClick = async (context, event) => {
  event.preventDefault();
  const isValid = await validateTid(context);
  if (isValid) {
    context.tidApplyButtonEl.style.display = "none";
    context.tidApplyButtonEl.textContent = "Applied !";
  }
};

function captureUTMParamsOnLoad() {
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
  let utmParamsString = "";
  utmParams.forEach(param => {
    const value = sessionStorage.getItem(param);
    if (value) {
      if (!utmParamsString) {
        utmParamsString += `${param}=${value}`;
      } else {
        utmParamsString += `&${param}=${value}`;
      }
    }
  });
  return utmParamsString;
};


function initializeGetStartedForm(form) {
  const context = {
    form: form,
    phoneInputEl: form.querySelector("input[name='phone']"),
    submitButtonEl: form.querySelector("button[type='submit']"),
    errorBoxEl: form.closest(".custom__search").querySelector(".error-box"),
    codePartEl: form.querySelector(".code-part"),
    tidErrorEl: form.querySelector(".warning-msg"),
    tidErrorMsgEl: form.querySelector(".warning-msg > p"),
    checkBoxEl: form.querySelector(".tid-checkbox"),
    tidInputEl: form.querySelector("#tidInput"),
    tidApplyButtonEl: form.querySelector("#applyTIDButton"),
    greenTickEl: form.querySelector("#greenTick"),
  };

  let utmParamsString = captureUTMParamsOnLoad();
  if (utmParamsString) {
    utmParamsString += `&landing_page=${window.location.origin + window.location.pathname}`;
    form.setAttribute("action", `${form.getAttribute("action")}?${utmParamsString}`);
  }

  context.phoneInputEl.addEventListener("keypress", (event) =>
    onPhoneInputKeyPress(context, event)
  );
  context.phoneInputEl.addEventListener("input", (event) =>
    onPhoneInputHandler(context)
  );
  context.submitButtonEl.addEventListener("click", (event) =>
    onGetStartedClicked(context, event)
  );

  context.checkBoxEl.addEventListener("change", function () {
    if (this.checked) {
      context.tidInputEl.disabled = false;
      context.tidApplyButtonEl.disabled = false;
    } else {
      context.tidInputEl.disabled = true;
      context.tidApplyButtonEl.disabled = true;
    }
  });

  context.tidInputEl.addEventListener("keydown", (event) => {
    if (["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].indexOf(event.key) === -1) {
      if (!/\d/.test(event.key) || context.tidInputEl.value.length >= 7) {
        event.preventDefault();
      }
    }
  });

  context.tidInputEl.addEventListener("input", (event) => {
    if (!/^\d*$/.test(event.target.value) || event.target.value.length > 7) {
      event.target.value = event.target.value.replace(/[^\d]/g, "").slice(0, 7);
    }
    onTIDInput(context, event);
  });

  context.tidInputEl.addEventListener("keydown", (event) => onTIDOnchange(context, event));
  context.tidInputEl.addEventListener("change", (event) => onTIDOnchange(context, event));
  context.tidInputEl.addEventListener("paste", (event) => onTIDPaste(context, event));
  context.tidInputEl.addEventListener("keypress", (event) => onTIDInputKeyPress(context, event));
  context.tidInputEl.addEventListener("keyup", (event) => onTIDInputKeyPress(context, event));
  context.tidInputEl.addEventListener("input", (event) => onTIDInput(context, event));
  context.tidApplyButtonEl.addEventListener("click", (event) => handleApplyBtnClick(context, event));
}

function captureUTMParameters() {
  const urlParams = new URLSearchParams(window.location.search);
  const mainUtmParams = ['utm_source', 'utm_medium', 'utm_campaign','utm_term', 'utm_content'];
  const hasMainUtmParams = mainUtmParams.some(param => urlParams.has(param));

  if (hasMainUtmParams) {
    mainUtmParams.forEach(param => {
      let utmValue = urlParams.get(param) || "any";
      sessionStorage.setItem(param, utmValue);
    });
  }
}

function init() {
  captureUTMParameters();
  const forms = document.querySelectorAll("form[id^='onboardingForm']");
  forms.forEach(initializeGetStartedForm);
}

document.addEventListener("DOMContentLoaded", init());
